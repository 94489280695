<template>
    <div class="home">
        <Row>
            <Form style="display:flex;justify-content:end" ref="formInline" :model="fromData" inline :label-width="10" label-position="right">
                <FormItem>
                    <DatePicker @on-change="fromData.startTime = $event" :value="fromData.startTime" format="yyyy-MM-dd" type="date" placeholder="开始时间" style="width: 180px">
                    </DatePicker>
                </FormItem>
                <FormItem>
                    <DatePicker @on-change="onChangeEndTime" :value="fromData.endTime" format="yyyy-MM-dd" type="date" placeholder="结束时间" style="width: 180px">
                    </DatePicker>
                </FormItem>
                <Button type="primary" shape="circle" icon="ios-search" @click="searchList()">搜索
                </Button>
            </Form>
        </Row>
        <div class="content">
            <div class="echart-box" id="E_zkcl" :style="
          `width:100%;height:600px;zoom:${zoom};transform:scale(${1 /
            zoom});transform-origin:0 0`
        "></div>
        </div>
    </div>
</template>
<script>
import { getElevatorRunDataStatistics } from '@/api/ststistics/index.js'
export default {
    data() {
        return {
            cityList: [{
                    value: 'New York',
                    label: 'New York'
                },
                {
                    value: 'London',
                    label: 'London'
                },
                {
                    value: 'Sydney',
                    label: 'Sydney'
                },
                {
                    value: 'Ottawa',
                    label: 'Ottawa'
                },
                {
                    value: 'Paris',
                    label: 'Paris'
                },
                {
                    value: 'Canberra',
                    label: 'Canberra'
                }
            ],
            model1: '',
            fromData: {
                startTime: this.formatDate(-30) + '',
                endTime: this.formatDate(0) + ' 23:59:59',
            },
            option: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    orient: 'vertical', //垂直显示
                    y: 'center', //延Y轴居中
                    x: 'right',
                    selectedMode: 'single'
                },
                grid: {
                    left: '3%',
                    right: '10%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    axisLabel: {
                        interval: 0,
                        rotate: 40
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {}
                },
                series: [{
                        name: 'Direct',
                        type: 'bar',
                        stack: 'total',
                        data: [320, 302, 301, 334, 390, 330, 320]
                    },
                    {
                        name: 'Mail Ad',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [120, 132, 101, 134, 90, 230, 210]
                    },
                    {
                        name: 'Affiliate Ad',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [220, 182, 191, 234, 290, 330, 310]
                    },
                    {
                        name: 'Video Ad',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [150, 212, 201, 154, 190, 330, 410]
                    },
                    {
                        name: 'Search Engine',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [820, 832, 901, 934, 1290, 1330, 1320]
                    }
                ]
            },
            zoom: 1
        }
    },
    mounted() {
        const width =
            document.documentElement.clientWidth || document.body.clientWidth
        this.zoom = 1 / (width / 1920)
        window.addEventListener('resize', () => {
            const width =
                document.documentElement.clientWidth || document.body.clientWidth
            this.zoom = 1 / (width / 1920)
        })
        this.searchList()
    },
    computed: {
        theme() {
            return this.$store.getters.theme
        }
    },

    watch: {
        'theme'(newVal, oldVal) {
            if (newVal != oldVal) {
                this.searchList()
            }
        }
    },

    methods: {
        searchList() {
            getElevatorRunDataStatistics(this.fromData).then(res => {
                let myChart = this.$echarts.init(document.getElementById('E_zkcl'))
                console.log(res)
                if (res.info) {
                    this.option.xAxis.data = res.info.categories.map((item) => {
                        return {
                            value: item,
                            textStyle: {
                                color: this.theme == 'dark' ? '#fff' : ''
                            }
                        }
                    })
                    res.info.series.forEach(item => {
                        if (item.type == 'column') {
                            item.type = 'bar'
                        }
                    })
                    this.option.series = res.info.series.filter(item => {
                        return item.name != "运行距离"
                    })
                    this.option.yAxis.axisLabel.color = this.theme == 'dark' ? '#fff' : ''
                }

                console.log(res)
                myChart.setOption(this.option)
            })
        },
        onChangeEndTime(e) {
            this.fromData.endTime = e + ' 23:59:59'
        }
    }
}
</script>
<style scoped>
.home {
    padding: 10px 30px 0 10px;
}
</style>